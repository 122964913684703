import '../fonts/fonts.css';

export default {
  borders: {
    none: 0,
    '1px': '1px solid',
    '2px': '2px solid',
    '4px': '4px solid',
    '8px': '8px solid',
  },
  breakpoints: ['30em', '48em', '62em', '80em'],
  colors: {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    text: '#000',
    background: '#fff',
    primary: '#0d1f2d',
    secondary: '#8d6346',
  },
  fonts: {
    body: 'Recursive, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'Recursive, inherit',
    mono: 'Recursive, Menlo, monospace',
  },
  fontSizes: [
    '0.875rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.875rem',
    '2.25rem',
    '3rem',
    '4rem',
    '4.5rem',
  ],
  fontWeights: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
    body: '400',
    heading: '700',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    none: '1',
    tight: '1.25',
    snug: '1.375',
    normal: '1.5',
    relaxed: '1.625',
    loose: '2',
    body: '1.625',
    heading: '1.25',
  },
  sizes: {
    px: '1px',
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    60: '15rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem',
    full: '100%',
    screenHeight: '100vh',
    screenWidth: '100vw',
  },
  shadows: {
    default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    outline: '0 0 0 3px rgba(141, 99, 70, 0.5)',
    none: 'none',
  },
  space: [
    '0',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.75rem',
    '2rem',
    '2.5rem',
    '3rem',
    '3.5rem',
    '4rem',
    '5rem',
    '6rem',
    '7rem',
    '8rem',
    '9rem',
    '10rem',
    '11rem',
    '12rem',
    '13rem',
    '14rem',
    '15rem',
    '16rem',
    '18rem',
    '20rem',
    '24rem',
  ],
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
  },
  buttons: {
    base: {
      display: 'inline-flex',
      appearance: 'none',
      alignItems: 'center',
      justifyContent: ['center', null, 'flex-start'],
      lineHeight: 1.2,
      borderRadius: 'md',
      fontWeight: 'semibold',
      py: 2,
      px: 3,
      cursor: 'pointer',
    },
    solid: {
      variant: 'buttons.base',
      backgroundColor: 'primary',
      color: 'background',
      '&:focus': {
        boxShadow: 'outline',
      },
      '&:hover': {
        backgroundColor: '#0c1c29',
      },
    },
    ghost: {
      variant: 'buttons.base',
      backgroundColor: 'background',
      color: 'primary',
      '&:focus': {
        boxShadow: 'outline',
      },
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      m: 0,
    },
    a: {
      color: 'inherit',
      textDecoration: 'underline',
      ':hover,:focus': {
        color: 'secondary',
      },
    },
    h1: {
      variant: 'text.heading',
      mb: 1,
      fontSize: 6,
      mt: 2,
    },
    h2: {
      variant: 'text.heading',
      mb: 1,
      fontSize: 5,
      mt: 2,
    },
    h3: {
      variant: 'text.heading',
      mb: 1,
      fontSize: 4,
      mt: 3,
    },
    h4: {
      variant: 'text.heading',
      mb: 1,
      fontSize: 3,
    },
    h5: {
      variant: 'text.heading',
      mb: 1,
      fontSize: 2,
    },
    h6: {
      variant: 'text.heading',
      mb: 2,
      fontSize: 1,
    },
  },
};
